<template>
  <div>
    <!-- 学校管理 -->
    <el-row :gutter="20">
      <!-- <area-tree @setQueryParams="handleQuery"></area-tree> -->
      <el-col :span="24">
        <!-- 搜索栏 -->
        <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
          <el-form-item>
            <el-select clearable filterable v-model="queryParams.cooperate" placeholder="请选择合作状态" @change="resetPageAndRefresh">
              <el-option label="合作" value="1"></el-option>
              <el-option label="未合作" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="queryParams.schoolName" clearable placeholder="请输入学校名称" @change="resetPageAndRefresh"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <base-import importUrl="members/school/importExcel" @finish="refresh" v-if="OA.includes('jcxx:xxgl:lead')"></base-import>
          </el-form-item>
          <!-- <el-form-item>
            <el-button size="small" class="el-icon-download" @click="downloadPath('model/school.xlsx')">下载模板</el-button>
          </el-form-item> -->
        </el-form>

        <div class="content-wrap">
          <div class="btn-wrap cfx">
            <el-button type="success" size="small" icon="el-icon-plus" v-if="OA.includes('jcxx:xxgl:add')" @click="handleForm(false)">添加</el-button>
          </div>
          <div v-if="schoolTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
          <!--列表-->
          <paged-table
            v-if="schoolTotal>0"
            :data="schoolList"
            :total="schoolTotal"
            :loading="tLoading"
            :isShowSelection="false"
            defaultSortProp="id"
            v-model="queryParams"
            @refresh="refresh"
          > 
            <el-table-column prop="schoolName" show-overflow-tooltip label="学校名称"></el-table-column>
            <el-table-column prop="code" show-overflow-tooltip label="学校标识码"></el-table-column>
            
            <el-table-column show-overflow-tooltip label="所在区域">
              <template slot-scope="scope">
                <span>{{ scope.row.extend.provinceName ? scope.row.extend.provinceName : '' }}</span> -
                <span>{{ scope.row.extend.cityName ? scope.row.extend.cityName : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="department" show-overflow-tooltip label="所属教育局"></el-table-column>
            <el-table-column show-overflow-tooltip label="办学层次">
              <template slot-scope="scope">
                <span>{{ scope.row.extend.levelName ? scope.row.extend.levelName : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="cooperate" label="合作状态">
              <template slot-scope="scope">
                <span v-if="scope.row.cooperate==0">未合作</span>
                <span v-if="scope.row.cooperate==1">合作</span>
              </template>
            </el-table-column>
            <el-table-column prop="extend.ceoName" show-overflow-tooltip label="ceo名称"></el-table-column>
            <el-table-column prop="extend.ceoMobile" show-overflow-tooltip label="手机号"></el-table-column>
            <el-table-column align="right" label="操作" width="180" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="success" @click="handleApply(scope.row)" title="分配应用"><img class="loginHead" src="@/assets/images/powers.svg" alt=""></el-button>
                <el-button size="mini" @click="settingCeo(scope.row)" icon="el-icon-setting"  type="primary" title="设置ceo"></el-button>
                <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
                <el-button size="mini" v-if="OA.includes('jcxx:xxgl:editor')" type="primary" @click="handleForm(true, scope.row,afterHandleForm)" icon="el-icon-edit" title="编辑"></el-button>
                <el-button size="mini" v-if="OA.includes('jcxx:xxgl:del')" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
              </template>
            </el-table-column>
          </paged-table>
        </div>
      </el-col>
    </el-row>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑学校' : '添加学校'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="学校名称：" prop="schoolName">
          <el-input v-model="form.schoolName" clearable></el-input>
        </el-form-item>
        <el-form-item label="学校标识码：" prop="code">
          <el-input v-model="form.code" clearable></el-input>
        </el-form-item>
        <el-form-item label="所在省：" prop="province">
          <el-select clearable filterable v-model="form.province" @change="provinceChange($event, form.city),resetForm()">
            <el-option v-for="item in provinceOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在市：" prop="city">
          <el-select clearable filterable v-model="form.city" @change="cityChange($event)">
            <el-option v-for="item in cityOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主管部门：" prop="department">
          <el-input v-model="form.department" clearable></el-input>
        </el-form-item>
        <el-form-item label="办学层次：" prop="level">
           <el-select clearable filterable v-model="form.level">
            <el-option v-for="item in schoolType" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合作状态：" prop="cooperate">
          <el-select v-model="form.cooperate" clearable filterable placeholder="请选择合作状态">
            <el-option label="未合作" :value="0"></el-option>
            <el-option label="合作" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="form.remark" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="学校详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="学校名称：" prop="schoolName">
          <span v-text="details.schoolName"></span>
        </el-form-item>
        <el-form-item label="学校标识码：" prop="code">
          <span v-text="details.code"></span>
        </el-form-item>
        <el-form-item label="主管部门：" prop="department">
          <span v-text="details.department"></span>
        </el-form-item>
        <el-form-item label="所在省：" prop="province">
          <span v-text="details.extend.provinceName"></span>
        </el-form-item>
        <el-form-item label="所在市：" prop="city">
          <span v-text="details.extend.cityName"></span>
        </el-form-item>
        <el-form-item label="办学层次：" prop="levelName">
          <span v-text="details.extend.levelName"></span>
        </el-form-item>
        <el-form-item label="合作状态：" prop="cooperate">
          <span v-text="details.cooperate == 1 ? '合作' : '未合作'"></span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
        <el-form-item label="CEO名称：" prop="city">
          <span v-text="details.extend.ceoName"></span>
        </el-form-item>
        <el-form-item label="手机号：" prop="city">
          <span v-text="details.extend.ceoMobile"></span>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 应用分配 -->
    <el-dialog title="应用分配" :visible.sync="applyVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="applyFileds" :model="applyForm" size="small">
        <el-form-item label="应用：" prop="schoolName">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checkedApplys"  @change="handleCheckedApplysChange">
            <el-checkbox :label="item" v-for="(item, index) in applyUses" :key="index"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="applyVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitApply">保存</el-button>
      </div>
    </el-dialog>
    <!-- 设置ceo -->
    <el-dialog title="设置ceo" :visible.sync="ceoVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="ruleFormFileds" :model="ruleForm" :rules="ruleFormRule" size="small">
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="ruleForm.mobile" @change="contant" clearable :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="成员名称：" prop="realName">
          <el-input v-model="ruleForm.realName" clearable disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="saveCeo">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'schoolManagement',
  components: {
    PagedTable,
    AreaTree,
    BaseImport
  },
  mixins: [pageMixin,provinces,download],
  data() {
    return {
      ceoVisible:false,
      BaseUrl,
      queryParams: {
        cooperate: null,
        schoolName: null
      },
      schoolType: [],
      ruleForm:{
        id:'',
        schoolId:'',
        mobile:'',
        realName:''
      },
      ruleFormRule:{
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        realName: [{ required: true, message: '请输入成员名称', trigger: 'blur' }],
      },
      form:{
        id:'',
        schoolName:'',
        code:'',
        department:'',
        province:'',
        city:'',
        level: '',
        cooperate: '',
        remark: ''
      },
      applyOption: [],
      details:{
        schoolName:'',
        department:'',
        code:'',
        extend:{},
        cooperate: '',
        remark: ''
      },
      cityOptions: [],
      applyVisible: false,
      applyForm: {
        schoolId: null,
        items: '',
      },
      checkAll: false,
      applyUses: [],
      checkedApplys: [],
      applyList: [],
      isIndeterminate: true,
      formRules:{
        schoolName: [{ required: true, message: '请输入学校名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入学校标识码', trigger: 'blur' }],
        department: [{ required: true, message: '请输入主管部门', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省份', trigger: 'blur' }],
        city: [{ required: true, message: '请选择市区', trigger: 'blur' }],
        level: [{ required: true, message: '请选择办学层次', trigger: 'blur' }],
        cooperate: [{ required: true, message: '请选择合作状态', trigger: 'blur' }],
      },

      pageApi:'membersSchoolList',
      insertApi:'membersSchoolAdd',
      editorApi:'membersSchoolEdit',
      deleteApi:'membersSchoolDelete',
      loadApi:'membersSchoolLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("basicSchool", ["schoolList", "schoolTotal"]),
  },
  methods: {
    
    ...mapActions("basicMember",["getSchoolUser"]),
    ...mapActions("basicSchool",["membersSchoolList", "membersSchoolAdd", "membersSchoolLoad", "membersSchoolEdit", "membersSchoolDelete", "operateAdminAppItemGetItem", "operateAdminAppItemSchoolAddList","membersSetCeo"]),
    resetForm() {
      console.log(111)
      this.form.city=""
    },
    handleCheckAllChange(val) {
      this.checkedApplys = val ? this.applyUses : [];
      this.idHandle(this.checkedApplys)
      this.isIndeterminate = false;
    },
    handleCheckedApplysChange(value) {
      this.idHandle(value)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.applyOption.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.applyOption.length;
    },
    async handleApply(row) {
      this.applyVisible = true
      this.applyUses = []
      this.applyForm.schoolId = row.id 
      this.checkedApplys = []
      let res = await this.operateAdminAppItemGetItem({ schoolId: row.id })
      this.applyList = res
      res.forEach(element => {
        this.applyUses.push(element.name)
        if(element.status == 1){
          this.checkedApplys.push(element.name)
        }
      });
      this.applyOption = res
      this.idHandle(this.applyUses)
    },
    // 处理id
    idHandle(res){
      this.applyForm.items = ''
      res.forEach(element => {
        this.applyList.forEach(ele => {
          if(element == ele.name){
            this.applyForm.items = this.applyForm.items+ele.id+','
          }
        });
      });
      this.applyForm.items = this.applyForm.items.substring(0, this.applyForm.items.lastIndexOf(','))
    },
    submitApply() {
      try{
        this.operateAdminAppItemSchoolAddList({...this.applyForm})
        if(!!res){
          this.$message.success("分配成功")
        }
      }catch(error){}
      this.applyVisible = false
    },
    async saveCeo() {
       this.$refs.ruleFormFileds.validate((valid) => {
          if (valid) {
            this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
              try{
                const res = await this.membersSetCeo(this.ruleForm)
                console.log(res)
                this.$message.success('设置ceo成功！')
                this.ceoVisible = false
                this.$refs.ruleFormFileds.resetFields();
                this.refresh()
              }catch(e) {
                log(e)
              }
            })
          }
       })
    },
    cancel() {
      this.ceoVisible = false
      this.$refs.ruleFormFileds.resetFields();
    },
    async contant(val) {
      this.form.userName = ''
      try{
        const res = await this.getSchoolUser({mobile:val,operate:1})
        this.ruleForm.realName = res.realName
        this.ruleForm.id = res.id
      }catch(e){
        log(e)
      }
    },
    settingCeo(row) {
      // console.log('row',row)
      this.ruleForm.schoolId = row.id
      this.ceoVisible= true
    },
    async afterHandleForm(row){
      if(row.province) this.cityOptions = await this.commonDictGetDictFPid({pid: row.province})
      if(row.city) this.areaOptions = await this.commonDictGetDictFPid({pid: row.city})
    },
    handleQuery(data){
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
        this.queryParams.city = null;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      this.refresh()
    },
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()

    this.schoolType = await this.commonDictGetDictFPid({pid: 104001})
  }
};
</script>